import { GeoPoint } from './dataTypes';

// export const getGeoPointFromLocation = (
//   location: google.maps.GeocoderResult | null | undefined,
// ): GeoPoint | undefined =>
//   location?.geometry?.location
//     ? [location?.geometry?.location?.lat(), location?.geometry?.location?.lng()]
//     : undefined;

export function getGeoPointFromLocation(
  location: google.maps.GeocoderResult | null,
): GeoPoint | undefined {
  if (!location || !location.geometry || !location.geometry.location) {
    return undefined;
  }

  const lat =
    typeof location.geometry.location.lat === 'function'
      ? location.geometry.location.lat()
      : location.geometry.location.lat;

  const lng =
    typeof location.geometry.location.lng === 'function'
      ? location.geometry.location.lng()
      : location.geometry.location.lng;

  return [lat as number, lng as number];
}
