import * as React from 'react';
import { TruckForMap } from '../utils/dataTypes';

export const ModalMapContext = React.createContext<
  [mapData: TruckForMap[], setMapData: (truckListData: TruckForMap[]) => void]
>(null!);

export const useMap = function useMap() {
  return React.useContext(ModalMapContext);
};
