import { authProvider } from '../utils/auth';
import { User } from '../utils/dataTypes';

const SUCCESS_HTTP_CODE = 200;
const CREATED_HTTP_CODE = 201;
const FORBIDDEN_HTTP_CODE = 403;
const NOT_FOUND_HTTP_CODE = 404;
const CONFLICT_HTTP_CODE = 409;

const { origin } = window.location;

const transformBody = (body: object): Record<string, any> => {
  const transformedBody: Record<string, any> = {};
  Object.entries(body).forEach((entry) => {
    if (Array.isArray(entry[1])) {
      transformedBody[entry[0]] = entry[1].map((elem) => {
        if (
          elem &&
          typeof elem === 'object' &&
          elem.id &&
          !(elem instanceof Date)
        ) {
          return elem.id;
        } else if (
          elem &&
          typeof elem === 'object' &&
          !(elem instanceof Date)
        ) {
          return transformBody(elem);
        } else {
          return elem;
        }
      });
    } else if (
      entry[1] &&
      typeof entry[1] === 'object' &&
      entry[1].id &&
      !(entry[1] instanceof Date)
    ) {
      transformedBody[entry[0]] = entry[1].id;
    } else if (
      entry[1] &&
      typeof entry[1] === 'object' &&
      !(entry[1] instanceof Date)
    ) {
      transformedBody[entry[0]] = transformBody(entry[1]);
    } else {
      transformedBody[entry[0]] = entry[1];
    }
  });
  return transformedBody;
};

export const checkAuth: (
  username: string,
  password: string,
) => Promise<User> = (username, password) => {
  const headers = new Headers();
  headers.set('X-User-Login', `${username}`);
  headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));

  return fetch(new URL('/api/user/auth', origin), { method: 'GET', headers })
    .catch((reason) => {
      const error = new Error();
      error.cause = reason;
      error.name = 'Fetch error';
      error.message = 'Network error has occurred';
      throw error;
    })
    .then((response) => {
      if (response.status !== SUCCESS_HTTP_CODE) {
        const error = new Error();
        error.cause = response;
        if (response.status) {
          error.name = response.status + ' ' + response.statusText;
        }
        error.message = 'Error fetching data';
        throw error;
      }
      return response.json().catch((reason) => {
        const error = new Error();
        error.cause = reason;
        error.name = 'Fetch error';
        error.message = 'Server responded with incorrect data';
        throw error;
      });
    });
};

export const authFetch: (
  input: string | URL,
  init?: RequestInit & {
    bodyObj?: object;
    responseType?: 'json' | 'stream' | 'blob';
  },
) => Promise<object | ReadableStream<Uint8Array> | null> = (
  input,
  init = {},
) => {
  const { bodyObj, responseType, ...restInit } = init;
  const fetchInit = { ...restInit, headers: new Headers() };
  fetchInit.headers.set('X-User-Login', `${authProvider.user?.username}`);
  fetchInit.headers.set(
    'Authorization',
    'Basic ' +
      btoa(authProvider.user?.username + ':' + authProvider.user?.password),
  );
  if (
    bodyObj &&
    typeof bodyObj === 'object' &&
    !(bodyObj instanceof FormData)
  ) {
    fetchInit.headers.set('Accept', 'application/json');
    fetchInit.headers.set('Content-Type', 'application/json');
    fetchInit.body = JSON.stringify(transformBody(bodyObj));
  } else if (
    bodyObj &&
    typeof bodyObj === 'object' &&
    bodyObj instanceof FormData
  ) {
    fetchInit.body = bodyObj;
  }
  return fetch(input, fetchInit)
    .catch((reason) => {
      const error = new Error();
      error.cause = reason;
      error.name = 'Fetch error';
      error.message = 'Network error has occurred';
      throw error;
    })
    .then((response) => {
      if (
        response.status !== SUCCESS_HTTP_CODE &&
        response.status !== CREATED_HTTP_CODE
      ) {
        const error = new Error();
        error.cause = response;
        if (response.status) {
          error.name = response.status + ' ' + response.statusText;
          switch (response.status) {
            case FORBIDDEN_HTTP_CODE:
              error.message = 'Insufficient permissions';
              break;
            case NOT_FOUND_HTTP_CODE:
              error.message = 'Not found';
              break;
            case CONFLICT_HTTP_CODE:
              error.message = 'Some field values invalidate unique constrain';
              break;
            default:
              error.message = 'Some server error has occurred';
          }
        } else {
          error.message = 'Network error has occurred';
        }
        throw error;
      }
      if (responseType === 'stream') {
        return response.body;
      }
      if (responseType === 'blob') {
        return response.blob();
      }
      return response.json().catch((reason) => {
        const error = new Error();
        error.cause = reason;
        error.name = 'Fetch error';
        error.message = 'Server responded with incorrect data';
        throw error;
      });
    });
};
