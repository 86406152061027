import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export default function LocationFieldControl({
  fieldName,
  label,
  disabled,
  formik,
}: {
  readonly fieldName: string;
  readonly label: [string, string];
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
}) {
  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  const lat = (
    <Box key={`${fieldName}longitude`} sx={{ height: '75px' }}>
      <TextField
        fullWidth
        size="small"
        disabled={disabled}
        id={`${fieldName}longitude`}
        name={`${fieldName}[0]`}
        label={`${label[0]}`}
        value={isNaN(formikValue[0]) ? '' : formikValue[0]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formikTouched &&
          Boolean(formikError && (formikError as Record<number, string>)[0])
        }
        helperText={
          formikTouched &&
          formikError &&
          (formikError as Record<number, string>)[0]
        }
      />
    </Box>
  );
  const lng = (
    <Box key={`${fieldName}longitude`} sx={{ height: '75px' }}>
      <TextField
        fullWidth
        size="small"
        disabled={disabled}
        id={`${fieldName}longitude`}
        name={`${fieldName}[1]`}
        label={`${label[1]}`}
        value={isNaN(formikValue[1]) ? '' : formikValue[1]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formikTouched &&
          Boolean(formikError && (formikError as Record<number, string>)[1])
        }
        helperText={
          formikTouched &&
          formikError &&
          (formikError as Record<number, string>)[1]
        }
      />
    </Box>
  );
  return (
    <>
      <Box key="left" sx={{ p: 1, width: '50%' }}>
        {lat}
      </Box>
      <Box key="right" sx={{ p: 1, width: '50%' }}>
        {lng}
      </Box>
    </>
  );
}
