import { apiKey } from './const';
import { Library } from '@googlemaps/js-api-loader';

export const center = { lat: 39.828922654821866, lng: -98.57947970000001 };
export const zoom = 5;
export { mapId } from './const';
export const getMapSettings = () => {
  const res: {
    apiKey: string;
    version: string;
    language: string;
    region: string;
    libraries: Library[];
  } = {
    apiKey,
    version: 'quarterly',
    language: 'en',
    region: 'US',
    libraries: [
      'core' as Library,
      'maps' as Library,
      'marker' as Library,
      'geocoding' as Library,
    ],
  };
  return res;
};
