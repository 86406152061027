const getPart = (
  address_components: google.maps.GeocoderAddressComponent[],
  partName: string,
) =>
  address_components.find((a_c) => a_c.types.includes(partName))?.short_name ||
  '';

export function toStringLocation(
  address_components: google.maps.GeocoderAddressComponent[],
): string {
  const postal_code = getPart(address_components, 'postal_code');
  // const country = getPart(address_components, 'country');
  const state = getPart(address_components, 'administrative_area_level_1');
  const locality = getPart(address_components, 'locality');
  const route = getPart(address_components, 'route');
  const street_number = getPart(address_components, 'street_number');
  // let res = country || '';
  let res = '';
  res = res + `${res && locality ? ', ' : ''}` + locality;
  res = state === locality ? res : res + `${res && state ? ', ' : ''}` + state;
  res = res + `${res && postal_code ? ', ' : ''}` + postal_code;
  res = res + `${res && route ? ', ' : ''}` + route;
  res = res + `${res && street_number ? ', ' : ''}` + street_number;
  return res;
}
