import React from 'react';
import { File } from '../../utils/dataTypes';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Typography } from '@mui/material';

interface FilePreviewProps {
  file: File;
  fileData: any;
  contentType: string;
}

const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts.pop()?.toUpperCase() || '' : '';
};

const fileTypeIcons = (fileName: string, contentType: string) => {
  let icon: React.ReactNode;
  const fileExtension = getFileExtension(fileName);

  if (
    contentType.includes('application/pdf') ||
    contentType.includes('image')
  ) {
    return null;
  }

  switch (contentType) {
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = <InsertChartIcon sx={{ fontSize: 100, color: '#4caf50' }} />;
      break;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = <DescriptionIcon sx={{ fontSize: 100, color: '#0288d1' }} />;
      break;
    default:
      icon = <InsertDriveFileIcon sx={{ fontSize: 100, color: '#8c8b8b' }} />;
  }

  return icon ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {icon}
      <Typography sx={{ fontWeight: '500' }}>{fileExtension}</Typography>
    </Box>
  ) : null;
};

const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  fileData,
  contentType,
}) => {
  const fileTypeIcon = fileTypeIcons(file.filename, contentType);

  if (fileTypeIcon) {
    return (
      <Box
        sx={{
          height: '200px',
          width: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          border: '1px solid #ddd',
          borderRadius: '8px',
        }}
      >
        {fileTypeIcon}
      </Box>
    );
  } else {
    return (
      <object
        data={fileData}
        type={file.contentType}
        style={{
          height: '200px',
          width: '200px',
          margin: 'auto',
          display: 'block',
        }}
        title={`${file.filename}`}
      />
    );
  }
};

export default FilePreview;
