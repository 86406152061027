import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { useSnackbar, useFileView } from '../../hooks';
import { File } from '../../utils/dataTypes';
import Loader from '../Loader';
import {
  FileDelete,
  FileDownloadBlob,
  FileDownloadStream,
} from '../../api/files';
import FilePreview from './filePreview';

export default function FileItem({
  file,
  disabled = false,
}: {
  file: File;
  disabled?: boolean;
}) {
  const [fileData, setFileData] = React.useState<string>('');
  const [isFileDataLoading, setIsFileDataLoading] =
    React.useState<boolean>(false);

  const queryClient = useQueryClient();
  const setSnackbarMessage = useSnackbar();
  const [, setModalFileData] = useFileView();

  const { mutate, isLoading } = useMutation(FileDelete, {
    onSuccess: () => {
      setSnackbarMessage({
        severity: 'success',
        message: 'Deleted',
      });
      queryClient.invalidateQueries(['files']);
    },
    onError: (error: Error) => {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    },
  });

  React.useEffect(() => {
    let url: string;
    (async () => {
      setIsFileDataLoading(true);
      const fileData = await FileDownloadBlob(file.id);
      setIsFileDataLoading(false);
      url = URL.createObjectURL(fileData);
      setFileData(url);
    })();
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, []);

  const handleDelete = () => {
    const result = confirm(
      `Are you sure you want to delete file ${file.filename}`,
    );
    if (result) {
      mutate(`${file.id}`);
    }
  };

  const handleImgClick = () => {
    if (fileData) {
      setModalFileData({ file, fileData });
    }
  };

  const previewAvailable = (contentType: string): boolean => {
    if (contentType.includes('pdf') || contentType.includes('image')) {
      return true;
    }

    return false;
  };

  const handleSaveFile = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.showSaveFilePicker) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const handle = await window.showSaveFilePicker({
          suggestedName: file.filename,
        });
        const writableStream = await handle.createWritable();
        await (await FileDownloadStream(file.id)).pipeTo(writableStream);
        await writableStream.close();
      } catch (error) {
        console.error('Error saving file:', error);
        setSnackbarMessage({
          severity: 'error',
          message:
            error instanceof Error ? error.message : JSON.stringify(error),
        });
      }
    } else {
      // Используем fallback
      downloadFileFallback(file.id, file.filename);
    }
  };

  const downloadFileFallback = async (fileId: string, fileName: string) => {
    try {
      const fileData = await FileDownloadBlob(fileId);
      const url = URL.createObjectURL(fileData);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
  };

  return (
    <Paper sx={{ height: '240px', width: '210px', backgroundColor: 'unset' }}>
      <Box
        sx={{
          width: '100%',
          padding: '5px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ marginLeft: 1 }}>
          <a
            href="#"
            target="_blank"
            onClick={handleSaveFile}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >{`${file.comment ? file.comment : file.filename}`}</a>
          <IconButton size="small" onClick={handleSaveFile}>
            <DownloadIcon />
          </IconButton>
        </Box>

        <IconButton
          aria-label="delete file"
          onClick={handleDelete}
          sx={{ padding: 0 }}
          disabled={disabled}
        >
          <DeleteForever />
        </IconButton>
      </Box>
      {!fileData ? null : (
        <>
          <FilePreview
            file={file}
            fileData={fileData}
            contentType={file.contentType}
          ></FilePreview>
          <Box
            onClickCapture={
              previewAvailable(file.contentType)
                ? handleImgClick
                : handleSaveFile
            }
            style={{
              height: '200px',
              width: '200px',
              position: 'relative',
              top: '-200px',
              left: '5px',
              cursor: 'pointer',
            }}
          />
        </>
      )}
      {!isFileDataLoading ? null : (
        <CircularProgress
          sx={{ display: 'block', margin: 'auto', marginTop: '50px' }}
        />
      )}
      <Loader visible={isLoading} />
    </Paper>
  );
}
