import * as React from 'react';
import { EntityType } from '../utils/dataTypes';

type ModalFormBasicData<T> = {
  entityType: EntityType;
  callback?: (entity: T) => void;
};

type ModalFormCreateData = {
  mode: 'Create';
};

type ModalFormViewEditData = {
  mode: 'Edit' | 'View';
  id: string;
};

export type ModalFormData<T = any> = (
  | ModalFormCreateData
  | ModalFormViewEditData
) &
  ModalFormBasicData<T>;

export const ModalFormContext = React.createContext<
  [
    modalFormData: ModalFormData | null,
    setModalFormData: (fileData: ModalFormData | null) => void,
  ]
>(null!);

export const useModalForm = function useModalForm() {
  return React.useContext(ModalFormContext);
};
