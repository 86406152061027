import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

export default function StartPage() {
  const theme = useTheme();

  return (
    <div
      style={{
        width: '90%',
        height: '100%',
        margin: '0 5% 0 5%',
        backgroundColor: theme.palette.login.main,
      }}
    >
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <img
          src={
            theme.palette.mode !== 'dark'
              ? '/4U_Logistics_Light.svg'
              : '/4U_Logistics_Dark.svg'
          }
          width={'552px'}
          style={{ marginTop: '10%', marginBottom: '10%' }}
        />
      </Container>
    </div>
  );
}
