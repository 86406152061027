import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';

import { ModalFormContext, ModalFormData } from '../hooks';
import { Loader } from '../components';
import {
  CreateCoordinator,
  CreateCoordinatorDriver,
  CreateCustomer,
  CreateDriver,
  CreateFacility,
  CreateLoad,
  CreateLocation,
  CreateOwner,
  CreateOwnerDriver,
  CreateTruck,
  CreateUser,
} from '../App';
import { FormOnClose } from '../utils/dataTypes';

export const ModalFormProvider = function ModalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [modalFormData, setModalFormData] =
    React.useState<ModalFormData | null>(null);

  const theme = useTheme();

  const handleCloseForm = (/*event: object, reason: string*/) => {
    setModalFormData(null);
  };
  const handleClose: FormOnClose = (eventType, affectedEntity) => {
    setModalFormData(null);
    if (affectedEntity && modalFormData?.callback && eventType === 'Create') {
      modalFormData.callback(affectedEntity);
    }
  };

  return (
    <ModalFormContext.Provider value={[modalFormData, setModalFormData]}>
      {children}
      <Modal
        open={!!modalFormData}
        onClose={handleCloseForm}
        closeAfterTransition
        aria-labelledby="modal-map"
        aria-describedby="modal-map"
        slotProps={{
          backdrop: {
            children: (
              <Close
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  height: '24px',
                  width: '24px',
                  color: grey[100],
                }}
                onClick={handleCloseForm}
              />
            ),
          },
        }}
      >
        <Slide direction="up" in={!!modalFormData}>
          <div
            style={{
              height: 'calc(100% - 40px)',
              width: 'calc(100% - 40px)',
              margin: '20px',
            }}
          >
            <Paper
              elevation={3}
              sx={{
                backgroundImage:
                  theme.palette.mode === 'dark'
                    ? 'url("/4U_Logistics_Background_Dark.svg")'
                    : 'url("/4U_Logistics_Background_Light.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '80% 80%',
                margin: '10px',
                height: 'calc(100% - 10px);',
                width: 'calc(100% - 20px);',
              }}
            >
              <React.Suspense fallback={<Loader visible />}>
                {!modalFormData
                  ? null
                  : {
                      Truck: <CreateTruck onClose={handleClose} />,
                      Load: <CreateLoad onClose={handleClose} />,
                      Owner: <CreateOwner onClose={handleClose} />,
                      OwnerDriver: <CreateOwnerDriver onClose={handleClose} />,
                      Coordinator: <CreateCoordinator onClose={handleClose} />,
                      CoordinatorDriver: (
                        <CreateCoordinatorDriver onClose={handleClose} />
                      ),
                      Driver: <CreateDriver onClose={handleClose} />,
                      Location: <CreateLocation onClose={handleClose} />,
                      User: <CreateUser onClose={handleClose} />,
                      Customer: <CreateCustomer onClose={handleClose} />,
                      Facility: <CreateFacility onClose={handleClose} />,
                    }[modalFormData.entityType]}
              </React.Suspense>
            </Paper>
          </div>
        </Slide>
      </Modal>
    </ModalFormContext.Provider>
  );
};
