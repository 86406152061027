import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export default function TextFieldControl({
  fieldName,
  label,
  placeholder,
  number = false,
  multiline,
  maxRows,
  disabled,
  formik,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly number?: boolean;
  readonly multiline?: boolean;
  readonly maxRows?: number;
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
}) {
  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  return (
    <Box
      key={fieldName}
      sx={
        !multiline
          ? { height: '75px' }
          : { height: 'auto', marginBottom: '30px' }
      }
    >
      <TextField
        fullWidth
        size="small"
        multiline={multiline}
        disabled={disabled}
        id={fieldName}
        name={fieldName}
        label={label}
        placeholder={placeholder}
        value={
          typeof formikValue === 'number'
            ? isNaN(formikValue)
              ? ''
              : formikValue
            : (maxRows
                ? (formikValue?.join && formikValue.join('\n')) || ''
                : formikValue) || ''
        }
        onChange={(event) => {
          if (number && Number.isFinite(Number(event.target.value))) {
            formik.handleChange(event);
          } else if (!number && maxRows) {
            const valueFromEvent = event.target.value.split('\n', maxRows);
            const newValue =
              valueFromEvent.length === 0 ? undefined : valueFromEvent;
            formik.setFieldValue(fieldName, newValue);
          } else if (!number && !maxRows) {
            formik.handleChange(event);
          }
        }}
        onBlur={formik.handleBlur}
        error={formikTouched && Boolean(formikError)}
        helperText={
          formikTouched &&
          formikError &&
          `${
            (typeof formikError === 'string' && formikError) ||
            (Array.isArray(formikError) &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore`
              formikError.find((err: string) => err)) ||
            'Validation error'
          }`
        }
      />
    </Box>
  );
}
