import { checkAuth } from '../api/api';
import { UserDataType, UserRole } from './dataTypes';

export interface AuthProvider {
  isAuthenticated: boolean;
  user: UserDataType | null;
  signin: (
    username: string,
    password: string,
    callback: (user: UserDataType | null) => void,
  ) => void;
  signout: (callback: VoidFunction) => void;
  hasRole: (role: UserRole) => boolean;
  hasRoles: (roles: UserRole[]) => boolean;
}

export const authProvider: AuthProvider = {
  isAuthenticated: false,
  user: null,
  signin(username, password, callback) {
    checkAuth(username, password).then(
      (response) => {
        authProvider.isAuthenticated = true;
        authProvider.user = {
          ...response,
          username,
          password,
          userRole: response.userRole,
        };
        callback(authProvider.user);
      },
      () => {
        authProvider.isAuthenticated = false;
        callback(null);
      },
    );
  },
  signout(callback) {
    authProvider.isAuthenticated = false;
    authProvider.user = null;
    setTimeout(callback, 1);
  },
  hasRole(role: UserRole) {
    return this.user?.userRole === role || false;
  },
  hasRoles(roles: UserRole[]) {
    if (!this.user?.userRole) {
      return false;
    }

    return roles.includes(this.user.userRole);
  },
};
