import { File, FileOfType, Query, Result } from '../utils/dataTypes';
import { authFetch } from './api';

export const FilesQuery: (
  query: Query<{
    linkedTo: string;
    fileOf: FileOfType;
    tags?: Record<string, string>;
  }>,
) => Promise<Result<File>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/file', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams) {
    url.searchParams.append('linkedTo', `${queryParams.linkedTo}`);
    url.searchParams.append('fileOf', `${queryParams.fileOf}`);
  }
  if (queryParams?.tags) {
    Object.entries(queryParams.tags).forEach(([key, value]) => {
      url.searchParams.append(`tags[${key}]`, `${value}`);
    });
  }
  return authFetch(url) as Promise<Result<File>>;
};

export const FileDelete = (fileId: string): Promise<File> => {
  const url = new URL(`/api/file/${fileId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<File>;
};

export const FileDownloadStream = (
  fileId: string,
): Promise<ReadableStream<Uint8Array>> => {
  const url = new URL(`/api/file/${fileId}/download`, origin);
  return authFetch(url, { method: 'GET', responseType: 'stream' }) as Promise<
    ReadableStream<Uint8Array>
  >;
};

export const FileDownloadBlob = (fileId: string): Promise<Blob> => {
  const url = new URL(`/api/file/${fileId}/download`, origin);
  return authFetch(url, {
    method: 'GET',
    responseType: 'blob',
  }) as Promise<Blob>;
};

export const FileUpload = (data: FormData): Promise<File> => {
  const url = new URL(`/api/file`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<File>;
};
